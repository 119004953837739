import request from './_config'
const path = `tiktok_seller`

export function getShopTiktokSellers(params) {
    return request({
        url: `${path}/product/get_list`,
        method: 'get',
        params: {
            shop_id: params.shop_id,
            page_number: params.page_number,
            page_size: params.page_size
        }
    })
}

export function authTiktokSeller(tiktok_account_id, params) {
    return request({
        url: `${path}/auth/${tiktok_account_id}`,
        method: 'get',
        params: {
            auth_code: params.auth_code,
            shop_code: params.shop_code
        }
    })
}
